import getVideoId from 'get-video-id';
import VimeoPlayer from '@vimeo/player';
import YouTubePlayer from 'youtube-player';

import { VIMEO, VIMEO_PLAYER_OPTS, YOUTUBE, YOUTUBE_PLAYER_OPTS } from '@lib/constants';

export default class VideoEmbed {
    static id = 'video-embed';

    constructor(node) {
        this.node = node;
        this.posterImageNode = this.node.querySelector('[data-ref="poster-image"]');
        this.playerNode = this.node.querySelector('[data-ref="player"]');
        this.controlsNode = this.node.querySelector('[data-ref="controls"]');
        this.playTriggerNode = this.node.querySelector('[data-ref="play"]');

        this.videoUrl = this.node.dataset.videoUrl;
        this.getVideoMetadata();
        if (this.posterImageNode.getAttribute('src') == '') {
            // Fetch poster image if the element doesn't already have a
            // source defined (as is the case when it has been overridden)
            this.fetchPosterImage();
        }
        this.setupIntersectionObserver();

        this.playTriggerNode.addEventListener('click', this.handlePlayTriggerClick.bind(this));
    }

    fetchPosterImage() {
        if (this.videoService === VIMEO) {
            const url = `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.videoUrl)}`;
            fetch(url)
                .then((response) => {
                    if (response.ok) return response.json();
                    const error = new Error(response.statusText);
                    error.response = response;
                    return Promise.reject(error);
                })
                .then((data) => {
                    this.posterImageNode.setAttribute('src', data.thumbnail_url);
                    this.posterImageNode.setAttribute('width', data.thumbnail_width);
                    this.posterImageNode.setAttribute('height', data.thumbnail_height);
                })
                .catch((error) => console.error(error));
        } else if (this.videoService === YOUTUBE) {
            this.posterImageNode.setAttribute(
                'src',
                `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`,
            );
        }
    }

    getVideoMetadata() {
        const { id, service } = getVideoId(this.videoUrl);
        this.videoId = id;
        this.videoService = service;
    }

    handlePlayTriggerClick() {
        // TODO: Abstract into generic `VideoPlayer` class once poster frames
        // are retrieved on the server
        if (this.videoService === VIMEO) {
            this.player = new VimeoPlayer(this.playerNode, {
                ...VIMEO_PLAYER_OPTS,
                url: this.videoUrl,
            });
            this.player.play();
        } else if (this.videoService === YOUTUBE) {
            this.player = YouTubePlayer(this.playerNode, {
                ...YOUTUBE_PLAYER_OPTS,
                videoId: this.videoId,
            });
            this.player.playVideo();
        } else {
            window.open(this.videoUrl, '_blank');
            return;
        }

        this.posterImageNode.classList.add('is-hidden');
        this.controlsNode.style.display = 'none';
    }

    setupIntersectionObserver() {
        // Pause video when node leaves viewport, if player has been
        // instantiated
        if (!('IntersectionObserver' in window)) return;
        const handleIntersection = ([entry]) => {
            if (!entry.isIntersecting && this.player) {
                if (this.videoService === VIMEO) {
                    this.player.pause();
                } else if (this.videoService === YOUTUBE) {
                    this.player.pauseVideo();
                }
            }
        };
        const intersectionOptions = {
            threshold: [0, 1],
            root: document,
            rootMargin: '-1px -1px -1px -1px',
        };
        new IntersectionObserver(handleIntersection, intersectionOptions).observe(this.node);
    }
}
