export default class WheelCapture {
    static id = 'wheel-capture';

    constructor(node) {
        this.node = node;
        this.node.addEventListener('wheel', this.handleWheel.bind(this));
    }

    handleWheel(event) {
        if (event.target.closest('[data-ref="scrollable"]')) {
            this.handleScrollableNodeWheel(event);
        }
    }

    handleScrollableNodeWheel(event) {
        const scrollableNode = event.target.closest('[data-ref="scrollable"]');
        const { clientWidth, clientHeight, scrollLeft, scrollWidth } = scrollableNode;
        const { deltaY } = event;

        // Don't redirect scrolling if node is taller than 75% of viewport
        // height
        if (clientHeight > document.documentElement.clientHeight * 0.75) return;

        // Redirect scrolling if node can be scrolled in the
        // corresponding horizontal direction
        if (
            (deltaY < 0 && scrollLeft > 0) ||
            (deltaY > 0 && scrollLeft < scrollWidth - clientWidth)
        ) {
            event.preventDefault();
            scrollableNode.scrollLeft += deltaY;
        }
    }
}
