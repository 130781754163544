import { debounce } from 'throttle-debounce';

import TabGroup from '@components/tab-group';

import { FETCH_OPTS } from '@lib/constants';

export default class EntryBrowser extends TabGroup {
    static id = 'entry-browser';

    constructor(node) {
        super(node);

        this.formNode = this.node.querySelector('[data-ref="form"]');
        this.filterToggleNode = this.node.querySelector('[data-ref="filter-toggle"]');
        this.filterNode = this.node.querySelector('[data-ref="filter"]');
        this.filterCheckboxNodes = this.node.querySelectorAll('[data-ref="checkbox"]');
        this.searchToggleNode = this.node.querySelector('[data-ref="search-toggle"]');
        this.searchInputNode = this.node.querySelector('[data-ref="search-input"]');
        this.searchClearNode = this.node.querySelector('[data-ref="search-clear"]');
        this.mainNode = this.node.querySelector('[data-ref="main"]');

        if (this.filterNode) {
            this.filterNode.addEventListener(
                'change',
                debounce(250, this.handleInputChange.bind(this)),
            );
        }

        if (this.searchInputNode) {
            this.searchInputNode.addEventListener(
                'keyup',
                debounce(250, this.handleSearchInputKeyUp.bind(this)),
            );
            this.searchInputNode.addEventListener(
                'keyup',
                this.handleSearchInputKeyUpSecondary.bind(this),
            );
        }

        if (this.filterNode || this.searchInputNode) {
            this.formNode.addEventListener('submit', this.handleFormSubmit.bind(this));
            window.addEventListener('popstate', this.handlePopState.bind(this));
        }
    }

    handleClick(event) {
        super.handleClick(event);
        if (event.target.closest('[data-ref="filter-toggle"]')) {
            this.handleFilterToggleClick();
        } else if (event.target.closest('[data-ref="search-toggle"]')) {
            this.handleSearchToggleClick();
        } else if (event.target.closest('[data-ref="search-clear"]')) {
            this.handleSearchClearClick();
        }
    }

    handleFilterToggleClick() {
        const expanded = !(this.filterToggleNode.getAttribute('aria-expanded') === 'true');
        this.filterToggleNode.setAttribute('aria-expanded', expanded.toString());
        this.filterNode.classList.toggle('is-expanded', expanded);

        this.filterCheckboxNodes.forEach((filterCheckboxNode) =>
            filterCheckboxNode.setAttribute('tabindex', expanded ? '0' : '-1'),
        );
    }

    handleFormSubmit(event) {
        event && event.preventDefault();
        this.submitFormData();
    }

    handleInputChange(event) {
        // XXX: The 'managed by' checkbox has an extra attribute to indicate
        // that its change event should cause the form to be submitted the
        // form normally (to lazily reset the filter UI) rather than being
        // handled via ajax
        const checkboxNode = event.target.closest('[data-ref="checkbox"]');
        if (checkboxNode && typeof checkboxNode.dataset.defaultBehaviourOnChange !== 'undefined') {
            this.formNode.submit();
        } else {
            this.submitFormData();
        }
    }

    handleKeyUp(event) {
        if (event.target.closest('[data-ref="search-input"]')) {
            this.handleSearchInputKeyUp(event);
        }
    }

    handlePopState() {
        // Respond to the `popstate` event by updating the form to match the
        // query parameters in the URL

        // Reset to the original server-rendered state
        this.formNode.reset();

        const params = new URLSearchParams(window.location.search);

        // XXX: Use `.keys()` to facilitate handling of multiple values.
        //
        // Parameters in state can refer to either checkboxes or text inputs.
        // Checkbox fields can have multiple values per key so need to be
        // handled carefully.
        //
        // The `.keys()` iterator will return a key multiple times if it has
        // multiple values, so first build a local list of unique keys.
        const keys = [];
        for (const key of params.keys()) if (!keys.includes(key)) keys.push(key);
        for (const key of keys) {
            // Get value(s) as array
            const values = params.getAll(key);

            // Update matching checkbox fields
            const checkboxNodes = this.node.querySelectorAll(
                `input[type='checkbox'][name='${key}']`,
            );
            for (const checkboxNode of checkboxNodes) {
                checkboxNode.checked = values.includes(checkboxNode.getAttribute('value'));
            }

            // Update matching `text` field, which we only need to do in the
            // case of single values
            if (values.length === 1) {
                const [value] = values;
                const textNode = this.node.querySelector(`input[name='${key}']`);
                textNode.value = value;
            }
        }

        this.submitFormData(false);
    }

    handleSearchClearClick() {
        this.closeSearch();
    }

    handleSearchInputKeyUp(event) {
        if (!['Escape', 'Enter'].includes(event.key)) {
            this.submitFormData();
        }
    }

    handleSearchInputKeyUpSecondary(event) {
        if (event.key === 'Escape') {
            this.closeSearch();
        }
    }

    handleSearchToggleClick() {
        const expanded = !(this.searchToggleNode.getAttribute('aria-expanded') === 'true');
        this.searchToggleNode.setAttribute('aria-expanded', expanded.toString());

        this.searchInputNode.setAttribute('tabindex', expanded ? '0' : '-1');
        this.searchClearNode.setAttribute('tabindex', expanded ? '0' : '-1');
        (expanded ? this.searchInputNode : this.searchToggleNode).focus();
    }

    closeSearch() {
        this.handleSearchToggleClick();
        if (this.searchInputNode.value !== '') {
            this.searchInputNode.value = '';
            this.submitFormData();
        }
    }

    fetchResults(url) {
        this.node.setAttribute('aria-busy', 'true');
        if (this.controller) this.controller.abort();
        this.controller = new AbortController();
        const { signal } = this.controller;
        fetch(url, { ...FETCH_OPTS, signal })
            .then((response) => response.text())
            .then((html) => this.renderResults(html))
            .catch((err) => err.name !== 'AbortError' && console.error(err))
            .finally(() => {
                this.controller = null;
                this.node.setAttribute('aria-busy', 'false');
            });
    }

    renderResults(html) {
        // If this component has tabs, re-render each of those, otherwise
        // re-render the contents of the main node
        const doc = new DOMParser().parseFromString(html, 'text/html');
        if (this.tabPanelNodes.length) {
            this.tabPanelNodes.forEach((tabPanelNode) => {
                const { name } = tabPanelNode.dataset;
                const sourceNode = doc.querySelector(`[data-ref="tab-panel"][data-name="${name}"]`);
                tabPanelNode.innerHTML = sourceNode.innerHTML;
                tabPanelNode.dispatchEvent(new CustomEvent('maestro:ajax:load', { bubbles: true }));
            });
        } else {
            const sourceNode = doc.querySelector('[data-ref="main"]');
            this.mainNode.innerHTML = sourceNode.innerHTML;
            this.mainNode.dispatchEvent(new CustomEvent('maestro:ajax:load', { bubbles: true }));
        }
    }

    submitFormData(pushState = true) {
        const data = new FormData(this.formNode);
        const params = new URLSearchParams(data);
        const fetchUrl = `?${params.toString()}`;
        this.fetchResults(fetchUrl);

        if (pushState) {
            const pushStateUrl = `?${params.toString()}`;
            window.history.pushState(null, '', pushStateUrl);
        }
    }
}
