import Cookies from 'js-cookie';

export default class CookieBanner {
    static id = 'cookie-banner';

    constructor(node) {
        this.node = node;

        this.name = 'maestro_arts_cookie_consent';
        this.expiry = 30;

        this.acceptButtonNode = this.node.querySelector('[data-ref="accept"]');
        this.rejectButtonNode = this.node.querySelector('[data-ref="reject"]');

        this.acceptButtonNode.addEventListener('click', this.handleAcceptButtonClick.bind(this));
        this.rejectButtonNode.addEventListener('click', this.handleRejectButtonClick.bind(this));

        this.maybeShowBanner();
        this.maybeUpdateConsents();
    }

    handleAcceptButtonClick() {
        // Set consent cookie with expiry, to automatically grant on next visit
        Cookies.set(this.name, 'true', {
            expires: this.expiry,
            secure: window.location.protocol === 'https',
        });

        this.updateConsents();
        this.hideBanner();
    }

    handleRejectButtonClick() {
        // Set a session cookie to avoid showing the banner on every page view
        Cookies.set(this.name, 'false', {
            secure: window.location.protocol === 'https',
        });

        this.hideBanner();
    }

    hideBanner() {
        this.node.setAttribute('hidden', true);
    }

    showBanner() {
        this.node.removeAttribute('hidden');
    }

    maybeShowBanner() {
        // Show the banner if no consent cookie is present
        const cookieValue = Cookies.get(this.name);
        if (typeof cookieValue === 'undefined') {
            this.showBanner();
        }
    }

    maybeUpdateConsents() {
        // Update cookie consents via gtag if the user has previously accepted
        const cookieValue = Cookies.get(this.name);
        if (cookieValue === 'true') {
            this.updateConsents();
        }
    }

    updateConsents() {
        // Update cookie consents via gtag
        if (typeof gtag !== 'undefined') {
            gtag('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted',
                functionality_storage: 'granted',
                personalization_storage: 'granted',
                security_storage: 'granted',
            });
        } else {
            console.warn('Couldn’t update consents: `gtag` is not available');
        }
    }
}
