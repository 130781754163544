import { createFocusTrap } from 'focus-trap';
import { THEME_SCOPE_HEADER } from '../../js/lib/constants';

export default class Header {
    static id = 'header';

    constructor(node) {
        this.node = node;
        this.navNode = this.node.querySelector('[data-ref="nav"]');
        this.openNode = this.node.querySelector('[data-ref="open"]');
        this.closeNode = this.node.querySelector('[data-ref="close"]');
        this.mainNode = document.getElementById('main');

        this.onDocumentKeyUp = this.handleDocumentKeyUp.bind(this);
        this.openNode.addEventListener('click', this.handleOpenClick.bind(this));
        this.closeNode.addEventListener('click', this.handleCloseClick.bind(this));
        window.addEventListener('maestro:theme:change', this.handleThemeChange.bind(this));

        this.focusTrap = createFocusTrap(this.navNode, {
            initialFocus: this.closeNode,
            setReturnFocus: this.openNode,
        });
    }

    handleCloseClick() {
        this.close();
    }

    handleDocumentKeyUp(event) {
        if (event.key === 'Escape') this.close();
    }

    handleOpenClick() {
        this.open();
    }

    handleThemeChange(event) {
        if (event.detail?.scope !== THEME_SCOPE_HEADER) return;
        switch (event.detail?.theme) {
            case 'light':
                this.node.classList.remove('theme-dark');
                this.node.classList.add('theme-light');
                break;
            case 'dark':
                this.node.classList.remove('theme-light');
                this.node.classList.add('theme-dark');
                break;
        }
    }

    close() {
        this.openNode.setAttribute('aria-expanded', 'false');
        this.navNode.setAttribute('hidden', true);
        document.removeEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.remove('has-open-modal');
        if (this.mainNode) this.mainNode.removeAttribute('aria-hidden', true);
        this.focusTrap.deactivate();
    }

    open() {
        this.openNode.setAttribute('aria-expanded', 'true');
        this.navNode.removeAttribute('hidden');
        document.addEventListener('keyup', this.onDocumentKeyUp);
        document.body.classList.add('has-open-modal');
        if (this.mainNode) this.mainNode.setAttribute('aria-hidden', true);
        this.focusTrap.activate();
    }
}
